import React from "react";
import styled from "styled-components";

export default function ProgressBar({progress, minted, total}) {
  return (
    <Progress className="flex flexColumn progress-container">
        <div className='mint-goal-icon custom-tooltip'> 50% Goal   <label className='tooltiptext'>
                <label className='tooltipTitle'>Mint Goal</label>
                <label className='tooltipContent'>Panda Millionaires has set a 50% minimum mint goal. If this is not achieved, you will be able to refund your NFT. </label>
        </label> </div>
        <div className="progressTitle">
            <h2>Total Minted</h2>
            <span>{progress}%</span>
        </div>
        <div className="progress">
            <span className="progress-bar" style={{ width: `${progress}%`}}></span>
        </div>
         <div className="progressFooter">
            <span>{minted}/{total}</span>
        </div>
    </Progress>
  );
}

const Progress = styled.div`
  width: 100%;
`;