// React Component 
import styled from "styled-components";
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMetadata, setLeftCount, setTotalCount, setMintBalance, setWlBalance } from "../../../store/reducers/connectionReducer";

// Wallet Component 
import * as nearAPI from 'near-api-js';
import { burnNFT } from "../../../utilities/burn";
import { getProjectDetails } from "../../../utilities/api";
import { nftMintFromContract } from "../../../utilities/contract";

// Page Component 
import Enleap from "../../../assets/img/logo/logo_small.png";
import Panda1 from "../../../assets/img/picture/panda1-min.png";
import Panda2 from "../../../assets/img/picture/panda2-min.png";
import Panda3 from "../../../assets/img/picture/panda3-min.png";
import Panda4 from "../../../assets/img/picture/panda4-min.png";
import Panda5 from "../../../assets/img/picture/panda5-min.png";
import Panda6 from "../../../assets/img/picture/panda6-min.png";
import Forest from "../../../assets/img/picture/forest-min.png";
import Twitter from "../../../assets/img/picture/twitter.png";
import Discord from "../../../assets/img/picture/discord.png";
import Linkedin from "../../../assets/img/picture/linkedin.png";
import Website from "../../../assets/img/picture/website.png";
import CountDown from "../../../components/Elements/CountDown";
import Loader from "../../../assets/img/logo/loader_black.gif";
import ProgressBar from "../../../components/Elements/ProgressBar";

export default function Content() {

    // Wallet CONST
    const { id } = useParams();
    const dispatch= useDispatch();
    const [whitelisted, setWhitelisted] = useState(null);
    const [contractID, setContractID] = useState(null);
	const env= useSelector(state => state.env)
	const wallet = useSelector(state => state.wallet)
    const metadata = useSelector(state => state.metadata)
	const leftCount = useSelector(state => state.leftCount)
    const totalCount = useSelector(state => state.totalCount)
	const wlBalance = useSelector(state => state.wlBalance)
	const nearConfig = useSelector(state => state.nearConfig)
	const sentimentMeta = useSelector(state => state.sentiment)
    const near = new nearAPI.Near(useSelector(state => state.nearConfig))
    const userAccountId = useSelector(state => state.userAccountId)
    const account = new nearAPI.Account(near.connection, userAccountId);
	const mintBalance = useSelector(state => state.mintBalance)
    const userBalance = useSelector(state => state.userBalance)
	const isWalletConnected = useSelector(state => state.isWalletConnected)
    const [userStatus, setUserStatus] = useState(null);
    const [mintedToken, setMintedToken] = useState(null);
    const [publicMintDate, setPublicMintDate] = useState(null);
    const [whitelistMintDate, setWhitelistMintDate] = useState(null);
    const [isWhitelistMintDate, setIsWhitelistMintDate] = useState(null);
    const [isPublicMintDate, setIsPublicMintDate] = useState(null);

    // Modal 
    const [mintType, setMintType] = useState(null);
    const [modal, setModal] = useState(false);
    const [promptModal, setPromptModal] = useState(false);
    const [modalMsg, setModalMsg] = useState(null);
    const handleModalClose = () => setModal(false);
    const handlePromptModalClose = () => setPromptModal(false);
    
    const { utils: { format: {formatNearAmount, parseNearAmount }}} = nearAPI;

    // Retrieve smart contract details  
	useEffect(async () => {

        // Get smart contract address & details 
        let smartContractID = nearConfig.contractName;
        setContractID(nearConfig.contractName);
        const contractAccount = new nearAPI.Account(near.connection, smartContractID);
        const metadata = await contractAccount.viewFunction(smartContractID, 'get_metadata');
        dispatch(setMetadata(metadata))
        dispatch(setLeftCount(metadata.tokens_minted))
        dispatch(setTotalCount(parseInt(metadata.total_supply)))
        
        // Public & whitelist mint date validation
        let publicDate = new Date(metadata.public_mint_start_time * 1000)
        let whitelistDate = new Date(metadata.wl_mint_start_time * 1000)
        setPublicMintDate(publicDate)
        setWhitelistMintDate(whitelistDate)
        setIsPublicMintDate(publicDate.getTime() <= new Date().getTime())
        setIsWhitelistMintDate(whitelistDate.getTime() <= new Date().getTime())
        
        // Get user wallet details 
        if (isWalletConnected && userAccountId) {
            const wlBalance = await contractAccount.viewFunction(smartContractID, 'get_whitelist_allowance', {"account_id": userAccountId});
            const mintBalance = await contractAccount.viewFunction(smartContractID, 'get_mint_allowance', {"account_id": userAccountId});
            const isWhitelisted = await contractAccount.viewFunction(smartContractID, 'whitelisted', {"account_id": userAccountId});
            const userStatus = await contractAccount.viewFunction(smartContractID, 'get_user_status', {"account_id": userAccountId});
            setWhitelisted(isWhitelisted);
            dispatch(setWlBalance(wlBalance));
            dispatch(setMintBalance(mintBalance));
            setUserStatus(userStatus);
        }
        else {
            setWhitelisted(false);
        }
	}, [leftCount, userAccountId]);
    
    // Get refund token 
    useEffect(async () => {
        if (isWalletConnected && userAccountId) {
            let smartContractID =  nearConfig.contractName;
            const contractAccount = new nearAPI.Account(near.connection, smartContractID); 
            const mintToken = await contractAccount.viewFunction(smartContractID, 'nft_tokens_for_owner', {"account_id": userAccountId});
            if(mintToken && mintToken.length){
                mintToken.map(async (item, index) => {
                    let refund = await contractAccount.viewFunction(smartContractID, 'get_refund_amount', {"token_id": item.token_id});
                    mintToken[index].refund = formatNearAmount(refund);
                })                    
            }
            setMintedToken(mintToken); 
        }
    }, [isWalletConnected, userAccountId]);
    

    // Hook mint callback function 
    useEffect(() => {
        // Function to check if minted
        async function checkResponse() {
            let url = new URL(window.location.href);
            setModalMsg(url.searchParams.get('errorMessage'));
            if (url.searchParams.get('mint')) 
                setModal(true);        
            if (url.searchParams.get('burn')) 
                showModal('You have burnt your NFT and got a refund successfully');
        }
        checkResponse();
    }, []);

    // If project loaded 
    if(whitelisted !== null && contractID !== null && (!userAccountId || (userAccountId && userStatus !== null))){
        return (
            <>
                <div id={(metadata.status == 'Failed')?'hide':'mint-bar'}>
                    <ProgressBar progress={parseFloat(leftCount / totalCount * 100).toFixed(2)} minted={leftCount} total={totalCount}/>
                </div>
                <div id='mintBG' style={{ background : 'url(' + Forest +') no-repeat center center'}}>
                </div>
                <img src={Panda1} id='panda1'/>
                <img src={Panda2} id='panda2'/>
                <img src={Panda3} id='panda3'/>
                <img src={Panda5} id='panda5'/>
                <img src={Panda6} id='panda6'/>
                <div id="leaves"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div>
                <div className="flexSpaceCenter container" id='launchpadContent'>
                    <div id="leftSide">
                        
                        <div id='leftSideContainer'>
                            {/* <div id='topSpan'>
                                <span className='custom-tooltip'>
                                     ESCROW 30d
                                     <label className='tooltiptext'>
                                         <label className='tooltipTitle'>Post Mint Safe Period</label>
                                         <label className='tooltipContent'>Funds go to 3rd party escrow and will be released in 30 days</label>
                                         <a href='#'>Learn More  </a>
                                    </label>
                                </span> 
                                <span className='custom-tooltip'>
                                     MINT GOAL 50%
                                     <label className='tooltiptext'>
                                         <label className='tooltipTitle'>Mint Goal</label>
                                         <label className='tooltipContent'>Mint Goal Description </label>
                                         <a href='#'>Learn More  </a>
                                    </label>
                                </span> 
                                <span className='custom-tooltip'>
                                     INIT FUND 50%
                                     <label className='tooltiptext'>
                                         <label className='tooltipTitle'>Initial Fund Percentage</label>
                                         <label className='tooltipContent'>Inital fund description</label>
                                         <a href='#'>Learn More  </a>
                                    </label>
                                </span> 
                            </div> */}
                            <h1 className="bold font60 project-title mb-4">Panda Millionaires</h1>
                                                      
                            <Tabs defaultActiveKey= {(metadata.status == 'Failed')?'burn':'mint'} id="projectTab" className="mb-3">
                                {(metadata.status == 'Failed')
                                ?
                                    <Tab eventKey="burn" title="Refund">
                                        {(!userAccountId)? 
                                            <div id='refundConnect'> 
                                                <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                    Connect Wallet
                                                </button> 
                                            </div>
                                        :
                                        <>
                                            <div className='row'>
                                                {(mintedToken && mintedToken.length)? mintedToken.map((item, index) => (
                                                    <>
                                                        <div className='col-sm-6'>
                                                            <div class='burn-item'>
                                                                <img src={"https://ipfs.fleek.co/ipfs/QmT16QwhWkqgWkvjJiZ6Zge9EJw9iwbUrsZUQwtmEJ7dqs/" + 1 + ".png"}/>
                                                                <h4> Token :  {item.token_id} </h4>
                                                                <p> Refund :  {parseFloat(item.refund).toFixed(5)} NEAR </p>
                                                                <button className='btn-primary refund-btn' onClick={() => handleRefund(item.token_id)}>
                                                                    Refund
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )):''}
                                            </div>
                                        </>
                                        }
                                    </Tab>
                                :
                                    <Tab eventKey="mint" title="Mint">
                                        <div  className={(isWhitelistMintDate && !isPublicMintDate)?'mint-info-list highlight':'mint-info-list'} id='wlMintInfo'>
                                            {
                                                (userStatus && userStatus.rare_whitelist > 0)?
                                                <>
                                                    <span className='wl'> OG-ed </span>
                                                    <span className='wl'> {userStatus.rare_whitelist} OG Mint </span>
                                                </>
                                                :
                                                <>
                                                    <span className='wl'> OG-ed </span>
                                                    <span className='wl'> 0 OG Mint </span>
                                                </>
                                            }
                                            <p className='mint-info-content'>
                                                Total OG
                                                <label> 30 <b>•</b> </label>
                                                Price 
                                                <label>FREE MINT</label>
                                            </p>
                                            <div className='countdown'>
                                                {(isWhitelistMintDate)?
                                                    (isPublicMintDate)? 
                                                        <p>  </p>
                                                    :
                                                        <p> Ongoing </p>
                                                :
                                                    <>
                                                        <p>Start In </p>
                                                        <CountDown type='whitelist' isWhitelist={isWhitelistMintDate} whitelistDate={whitelistMintDate}/>
                                                    </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                {
                                                    (!isWalletConnected)
                                                    ?
                                                        <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                            Connect Wallet
                                                        </button>
                                                    :
                                                        (!isWhitelistMintDate || userStatus.rare_whitelist <= 0 || (metadata.status == 'Failed' || metadata.status == 'Success'))?'' :
                                                        <>
                                                            <button className='btn-primary connectWallet' onClick={() => headerBtnHandle(2)}>
                                                                    {leftCount >=  totalCount? 'Mint Out'  :  'OG Mint'}
                                                            </button>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div  className={(isWhitelistMintDate && !isPublicMintDate)?'mint-info-list highlight':'mint-info-list'} >
                                            {
                                                whitelisted
                                                ?
                                                    <>
                                                        <span className='wl'> Whitelisted </span>
                                                        <span className='wl'> {wlBalance} Whitelist Mint </span>
                                                    </>
                                                :
                                                <>
                                                <span className='wl'> Whitelisted </span>
                                                <span className='wl'> 0 Whitelist Mint </span>
                                            </>
                                            }
                                            <p className='mint-info-content'>
                                                Total Whitelist
                                                <label> 350 <b>•</b> </label>
                                                Price 
                                                <label>  {formatNearAmount(metadata.wl_mint_price)} NEAR</label>
                                            </p>
                                            <div className='countdown'>
                                                {(isWhitelistMintDate)?
                                                    (isPublicMintDate)? 
                                                        <p>  </p>
                                                    :
                                                        <p> Ongoing </p>
                                                :
                                                    <>
                                                        <p>Start In </p>
                                                        <CountDown type='whitelist' isWhitelist={isWhitelistMintDate} whitelistDate={whitelistMintDate}/>
                                                    </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                {
                                                    (!isWalletConnected)
                                                    ?
                                                        <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                            Connect Wallet
                                                        </button>
                                                    :
                                                        (!isWhitelistMintDate || !whitelisted || wlBalance <= 0 || (metadata.status == 'Failed' || metadata.status == 'Success'))?'' :
                                                        <>
                                                            <button className='btn-primary connectWallet' onClick={() => headerBtnHandle(1)}>  
                                                                {leftCount >=  totalCount? 'Mint Out'  :  'Whitelist Mint'}
                                                            </button>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div className={isPublicMintDate?'mint-info-list highlight':'mint-info-list'} id='publicMintInfo'>
                                            <span> Public Sale </span>
                                            <p className='mint-info-content'>
                                                Price 
                                                <label>   {formatNearAmount(metadata.mint_price)} NEAR  <b>•</b></label>
                                                Duration 
                                                <label>  120H </label>
                                            </p>
                                            <div className='countdown'>
                                                {(isPublicMintDate)?
                                                    (metadata.status == 'Failed' || metadata.status == 'Success')
                                                    ?
                                                        <p> Ended </p>
                                                    :
                                                       <>
                                                            <p>End In </p>
                                                            <CountDown type='publicEnd'/>
                                                       </>
                                                :
                                                    <>
                                                        <p>Start In </p>
                                                        <CountDown type='public' isPublic={isPublicMintDate} publicDate={publicMintDate}/>
                                                    </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                {
                                                
                                                    (!isWalletConnected)
                                                    ?
                                                        <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                            Connect Wallet
                                                        </button>
                                                    :
                                                        (!isPublicMintDate || (metadata.status == 'Failed' || metadata.status == 'Success') )? 
                                                        ''
                                                        :
                                                        <>
                                                            <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                                { leftCount >=  totalCount? 'Mint Out' : ' Mint Now' }
                                                            </button>
                                                        </>
                                                }
                                            </div>
                                        </div>                                        
                                    </Tab>
                                }
                                <Tab eventKey="details" title="Details">
                                    <div id='bottomSpan'>
                                        <div className='spanItem'>
                                            <label> Project Status </label>
                                            {(metadata.status == 'Failed')
                                            ?
                                                <span> Failed </span>
                                            :
                                                (metadata.status == 'Success')
                                                ?
                                                    <span> Ended </span>
                                                :
                                                    <span> { isWhitelistMintDate || isPublicMintDate?'Ongoing':'Coming Soon' } </span>
                                            }
                                        </div>
                                        <div className='spanItem'>
                                            <label> Total Items </label>
                                            <span> {totalCount} </span>
                                        </div>
                                        <div className='spanItem active' >
                                            <label> Mint Price </label>
                                            <span> {formatNearAmount(metadata.mint_price)} NEAR </span>
                                        </div>
                                    </div>
                                    <h2 className="font15 regular" >
                                    Panda Millionaires is NFT series composed of 444 hand drawn combinations of red panda 🐼 Panda Millionaires is the first NFT project, which will develop its own jackpot player versus player. Panda Millionaires is not only about NFT but mostly a DAO planning to build big things on Near.
                                    </h2>
                                </Tab>
                                <Tab eventKey="roadmap" title="Roadmap">
                                    <div id='roadmapContent'>
                                        <div className='row fa-step'>
                                            <span className='step-icon'>1</span>
                                            <div className='col-12 mb-3'>
                                                <h3>PHASE 1 : BUILDING THE FOUNDATIONS</h3> 
                                                <p>Assemble a team of Developers and Community Managers ✔ Unveiling social networks to the public ✔ Build the Discord community and reward our most loyal members ✔</p> 
                                            </div>                                                
                                            <div className='fa-step-line'></div>
                                        </div> 
                                        <div className='row fa-step'>
                                            <span className='step-icon'>2 </span>
                                            <div className='col-12 mb-3'>
                                                <h3>PHASE 2 : IMPLEMENTATION OF THE PROJECT</h3> 
                                                <p>Start the mint with Enleap ! Implement Holder Verification Creation of the DAO Club and PandaBank (Millionaire Club)</p> 
                                            </div>                                                
                                            <div className='fa-step-line'></div>
                                        </div> 
                                        <div className='row fa-step'>
                                            <span className='step-icon'>3 </span>
                                            <div className='col-12 mb-3'>
                                                <h3>PHASE 3 : CREATION OF THE JACKPOT !</h3> 
                                                <p>Creation of our own Jackpot Beta for holders Added features for our jackpot site Marketing for the Jackpot</p> 
                                            </div>                                                
                                            <div className='fa-step-line'></div>
                                        </div> 
                                        <div className='row fa-step'>
                                            <span className='step-icon'> 4 </span>
                                            <div className='col-12 mb-3'>
                                                <h3>PHASE 4 : MORE MORE MORE..</h3> 
                                                <p>Adding a new game Collaboration with big project More soon on the Roadmap V2</p> 
                                            </div>                                                
                                            <div className='fa-step-line'></div>
                                        </div> 
                                    </div>
                                </Tab>
                               
                            </Tabs>   

                            <div className='social-item mt-5'>
                                <a target="_blank" rel="noreferrer" className='topSocial' style={{ padding: "15px 25px" }} href='https://twitter.com/PandamilNFT'><img src={Twitter} alt="twitter"/></a>
                                <a target="_blank" rel="noreferrer" className='topSocial' href='https://discord.gg/mQnZuysV4n'><img src={Discord} alt="discord"/></a>
                                <a target="_blank" rel="noreferrer" className='topSocial' href='https://pandamillionaires.club/'><img src={Website} alt="website"/></a>
                            </div>        

                            <div className='powered'>
                                <h3> Powered by - Enleap <img src={Enleap}/> </h3>
                            </div> 
                        </div>
                    </div>
                </div>
                
                <Modal className='custom-modal' show={modal} onHide={handleModalClose} >
                    <Modal.Body>
                        <div style={{ textAlign :"center"}}> 
                            <p className="font20">{ (modalMsg)?decodeURIComponent(modalMsg):"Congratulation, you have minted successfully!"}</p>
                            <div className="promptBtnSection">
                                <button className="promptClose" onClick={handleModalClose}> ✕  </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className='custom-modal prompt-modal' show={promptModal} onHide={handlePromptModalClose} >
                    <Modal.Body>
                        <div  className='promptBody'>
                            <div>
                                <h3> You can read more about our Enleap minimum mint goals & escrow policies here </h3>
                                <p> DYOY always! Enleap/NTU does not take any responsibility for your investment decisions.</p>
                                <p> Panda Millionaires has set a 50% minimum mint goal. If this is not achieved within the mint duration (120 houts), you will be able to burn your NFT and get a 87% refund.</p>
                                <p>  If the mint is successful, 50% of the funds will be release immediately to the team and 50% will be released after a 30 day escrow period. </p>
                                <button id="promptMintBtn" className='btn btn-primary'  onClick={() => mint()}> Mint Now </button>
                                <div className="promptBtnSection">
                                    <button className="promptClose" onClick={handlePromptModalClose}> ✕  </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    else 
    {
        return (
            <div id="loader">
                <p>Loading...</p>
            </div>
        )
    }

    
    // FUnction to show moadl 
    function showModal(msg){
        setModalMsg(msg);
        setModal(true);
    }
    

    // Main function to proceed with mint 
    function mint(){

        // Get mint quantity
        let mintQuantity = document.getElementById("mintQuantity");
        let quantity = 1;

        // Validate mint quantity 
        if(quantity > (totalCount - leftCount))
            showModal('Invalid Quantity : Quantity is more than NFT mint left');
        else 
        {
            // Mint 
            nftMintFromContract(wallet.account(), userBalance, quantity, wlBalance, mintBalance, metadata, contractID, isWhitelistMintDate, isPublicMintDate, mintType).then(function(response){
                if(response != 'success')
                    showModal(response)
            });
        }
    }

    // Handle refund
    function handleRefund(token){
        if(metadata.status == 'Failed'){
            burnNFT(wallet.account(), token, contractID).then(function(response){
                if(response != 'success')
                    showModal(response)
            });
        }
        else 
            alert('Invalid action');
    }
    
    // Header button handle 
	function headerBtnHandle(type=null){
		if (isWalletConnected)
        {
            if(leftCount >= totalCount)
                showModal('Mint Out');
            else 
            {
                if(isPublicMintDate || isWhitelistMintDate){
                    setPromptModal(true);
                    setMintType(type)
                }
                else 
                    showModal('Coming Soon');
            }
        }
        else 
        {
            wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
        }
	}
}



const GifItem = styled.div`
    display: flex;
    flex-wrap: nowrap;
    ${(props) => (props.nftCount > 10 ? " animation: nft-10 5s steps(1) infinite;" : props.nftCount > 5 ? " animation: nft-5 2s steps(1) infinite;" :  props.nftCount > 3 ?" animation: nft-3 2s steps(1)  infinite;" :"")};

    @keyframes nft-10 {0% {transform:translate(0);}10%{transform:translate(-100%);}20%{transform:translate(-200%);}30%{transform:translate(-300%);}40%{transform:translate(-400%);}50%{transform:translate(-500%);}60%{transform:translate(-600%);}70%{transform:translate(-700%);}80%{transform:translate(-800%);}90%{transform:translate(-900%);}100% {transform:translate(-1000%);}}
    @keyframes nft-5 {0% {transform:translate(0);}20% {transform:translate(-100%);}40% {transform:translate(-200%);}60% {transform:translate(-300%);}80% {transform:translate(-400%);}100% {transform:translate(-500%);}}
    @keyframes nft-3 {0% {transform:translate(0);}33%{transform:translate(-100%);}66%{transform:translate(-200%);}100% {transform:translate(-300%);}}

`;
